import { default as course_45work6E4PPdAgq4Meta } from "/app/src/pages/course-work.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as course_45workLlEp170uJjMeta } from "/app/src/pages/old/course-work.vue?macro=true";
import { default as indexjUM3Z4fZV8Meta } from "/app/src/pages/old/index.vue?macro=true";
import { default as profilebRkZuHTLqtMeta } from "/app/src/pages/old/profile.vue?macro=true";
import { default as _91id_93ctQaMKcfcuMeta } from "/app/src/pages/old/projects/[id].vue?macro=true";
import { default as referat5OM1iSwMHtMeta } from "/app/src/pages/old/referat.vue?macro=true";
import { default as _91slug_93gSWdNg3ftvMeta } from "/app/src/pages/old/topics/[slug].vue?macro=true";
import { default as indexRHWSm6tCNfMeta } from "/app/src/pages/old/topics/index.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as _91id_93VS9sDAIO5LMeta } from "/app/src/pages/projects/[id].vue?macro=true";
import { default as referatfLCnNSpLpMMeta } from "/app/src/pages/referat.vue?macro=true";
import { default as reportMuE0AUDbW1Meta } from "/app/src/pages/report.vue?macro=true";
import { default as textW8iLXWPybWMeta } from "/app/src/pages/text.vue?macro=true";
import { default as _91slug_93SKy0faSzi6Meta } from "/app/src/pages/topics/[slug].vue?macro=true";
import { default as indexuosGcRMc9GMeta } from "/app/src/pages/topics/index.vue?macro=true";
import { default as uiz6gISnKFJBMeta } from "/app/src/pages/ui.vue?macro=true";
import { default as vkL6SyM0WULbMeta } from "/app/src/pages/vk.vue?macro=true";
import { default as yandexXhMtitAb9sMeta } from "/app/src/pages/yandex.vue?macro=true";
export default [
  {
    name: "course-work",
    path: "/course-work",
    component: () => import("/app/src/pages/course-work.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "old-course-work",
    path: "/old/course-work",
    component: () => import("/app/src/pages/old/course-work.vue").then(m => m.default || m)
  },
  {
    name: "old",
    path: "/old",
    component: () => import("/app/src/pages/old/index.vue").then(m => m.default || m)
  },
  {
    name: "old-profile",
    path: "/old/profile",
    component: () => import("/app/src/pages/old/profile.vue").then(m => m.default || m)
  },
  {
    name: "old-projects-id",
    path: "/old/projects/:id()",
    meta: _91id_93ctQaMKcfcuMeta || {},
    component: () => import("/app/src/pages/old/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: "old-referat",
    path: "/old/referat",
    component: () => import("/app/src/pages/old/referat.vue").then(m => m.default || m)
  },
  {
    name: "old-topics-slug",
    path: "/old/topics/:slug()",
    component: () => import("/app/src/pages/old/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "old-topics",
    path: "/old/topics",
    component: () => import("/app/src/pages/old/topics/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    meta: _91id_93VS9sDAIO5LMeta || {},
    component: () => import("/app/src/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: "referat",
    path: "/referat",
    component: () => import("/app/src/pages/referat.vue").then(m => m.default || m)
  },
  {
    name: "report",
    path: "/report",
    component: () => import("/app/src/pages/report.vue").then(m => m.default || m)
  },
  {
    name: "text",
    path: "/text",
    component: () => import("/app/src/pages/text.vue").then(m => m.default || m)
  },
  {
    name: "topics-slug",
    path: "/topics/:slug()",
    component: () => import("/app/src/pages/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "topics",
    path: "/topics",
    component: () => import("/app/src/pages/topics/index.vue").then(m => m.default || m)
  },
  {
    name: "ui",
    path: "/ui",
    component: () => import("/app/src/pages/ui.vue").then(m => m.default || m)
  },
  {
    name: "vk",
    path: "/vk",
    component: () => import("/app/src/pages/vk.vue").then(m => m.default || m)
  },
  {
    name: "yandex",
    path: "/yandex",
    component: () => import("/app/src/pages/yandex.vue").then(m => m.default || m)
  }
]
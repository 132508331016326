<script setup lang="ts">
interface FooterProps {
  primary?: boolean;
}

withDefaults(defineProps<FooterProps>(), {
  primary: true,
});

type FooterLink = { title: string; link: string };
const links: Array<FooterLink> = [
  {
    title: 'Пользовательское соглашение',
    link: '/termofuse.pdf',
  },
  {
    title: 'Политика конфиденциальности',
    link: '/privacy.pdf',
  },
  {
    title: 'Публичная оферта',
    link: '/offer.pdf',
  },
];
</script>

<template>
  <footer
    class="footer-new"
    :class="[primary ? 'footer-new_primary' : 'footer-new_white']"
  >
    <div class="logo footer__logo">
      <img
        src="/logo-new.svg"
        alt=""
      />
      <div class="logo__brand">Бегемот</div>
    </div>
    <div class="footer__info">
      <div class="footer__address">
        г. Москва, ул. Краснопролетарская, д. 7, помещение 4/п
        <br />
        ОГРН 1247700367452 ИНН 9707030430
      </div>
      <div class="footer__copyright">ООО Бегемот ЭйАй © 2024</div>
      <div class="footer_links">
        <a
          v-for="link in links"
          :key="link.title"
          class="link"
          :href="link.link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="text-decoration-underline">{{ link.title }}</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer-new {
  padding: 64px 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 32px 32px 0px 0px;

  .logo {
    .logo__brand {
      color: $new-fr-contrast;
      font-size: 18px;
      font-weight: 800;
      line-height: normal;
    }
  }

  &.footer-new_primary {
    background: #fff;
    margin: 0 20px;

    @include media-breakpoint-down(md) {
      margin: 0;
      background: white;
    }
  }

  &.footer-new_white {
    background: transparent;
  }

  .footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .footer__info {
    display: flex;
    flex-direction: column;
    gap: 16px;

    color: $new-fr-gray;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .footer_links {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      color: $new-fr-theme;
      font-size: 14px;
      font-weight: 400;
      line-height: 1rem;

      &:hover {
        color: rgba($new-fr-theme, 0.7);
      }
    }
  }
}
</style>

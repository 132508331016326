<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/AuthStore';
const emit = defineEmits(['closeAuth']);
const authStore = useAuthStore();

onMounted(() => {
  if (!window) {
    return;
  }
  setTimeout(() => {
    window.YaAuthSuggest?.init(
      {
        client_id: '6f2a890c530e41b9a4e459156c472d8e',
        response_type: 'token',
        redirect_uri: `https://${window.location.host}/yandex`,
      },
      `https://${window.location.host}/yandex`,
      {
        view: 'button',
        parentId: 'yandex',
        buttonSize: 'm',
        buttonView: 'main',
        buttonTheme: 'light',
        buttonBorderRadius: '10',
        buttonIcon: 'ya',
      }
    )
      .then(({ handler }: { handler: any }) => handler())

      .then(async ({ access_token }: { access_token: string }) => {
        await authStore.loginByYandex(access_token);
        emit('closeAuth');
      });
  }, 1);
});
</script>

<template>
  <div id="yandex" />
</template>

<style scoped lang="scss">
#yandex {
}
</style>

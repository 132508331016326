<script setup lang="ts">
// import VButton from '~/components/common/form/VButton.vue';
import VHeader from '~/components/new/VHeader.vue';
import VFooter from '~/components/new/VFooter.vue';

const props = defineProps({
  error: {
    type: Object,
    default() {
      return {};
    },
  },
});
const handleError = () => clearError({ redirect: '/' });
//
console.log(props.error);
</script>
<template>
  <div class="error-page">
    <v-header schema="white" />
    <div class="container">
      <div class="error-content">
        <template v-if="error.statusCode == 404">
          <h1>404</h1>
          <div class="error-content__text">
            <h2>Такой страницы нет</h2>
            <p>Возможно, страница была удалена или в запросе был указан неверный адрес.</p>
          </div>
          <nuxt-link
            class="new-button new-button_secondary animation-scale"
            to="/"
          >
            На главную
          </nuxt-link>
        </template>
        <template v-else-if="error.statusCode == 502">
          <h1>502</h1>
          <div class="error-content__text">
            <h2>Выполняется обновление...</h2>
            <p>Попробуйте обновить страницу или зайти через несколько минут.</p>
          </div>
        </template>
        <template v-else>
          <h1>Упс!</h1>
          <div class="error-content__text">
            <h2>Что-то пошло не так...</h2>
            <p>Мы знаем о проблеме и уже работаем. Попробуйте обновить страницу или зайти попозже.</p>
          </div>
          <new-button
            class="animation-scale"
            schema="secondary"
            @click="handleError"
          >
            На главную
          </new-button>
        </template>
      </div>
    </div>
    <v-footer />
  </div>
</template>
<style lang="scss">
.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 100px;

  .footer-new {
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 50px 0;
    }
  }

  .error-content {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;

    h1 {
      color: $new-fr-theme;
      text-align: center;
      font-size: 120px;
      font-weight: 200;
      line-height: 120px;
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        font-size: 80px;
        line-height: 80px;
      }
    }

    .error-content__text {
      text-align: center;

      h2 {
        color: $new-fr-contrast;
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 16px;
        @include media-breakpoint-down(md) {
          font-size: 28px;
        }
      }

      p {
        color: $new-fr-gray;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        @include media-breakpoint-down(md) {
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="dropdown"
    :class="{ dropdown_open: d_open, dropdown_disabled: disabled }"
    @mouseleave="handleMouseLeave"
    @mouseenter="handleMouseEnter"
  >
    <div
      ref="toggle"
      class="dropdown__toggle"
      :class="c_toggleClass"
      @click.prevent="handleClick"
      @contextmenu.prevent.stop="handleContextMenu"
    >
      <slot />
    </div>
    <div
      ref="body"
      class="dropdown__popper-body"
      @click.prevent.stop="toggle"
    >
      <transition name="dropdown">
        <div
          v-if="d_open"
          :class="bodyClass"
          :style="c_bodyStyle"
        >
          <slot
            name="body"
            :toggle="toggle"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VDropdown',
  props: {
    width: {
      type: String,
      default: 'auto',
    },
    padding: {
      type: [String],
      default: '0',
    },
    overflow: {
      type: [String],
      default: 'auto',
    },
    hoverOpen: {
      type: Boolean,
      default: false,
    },
    contextMenu: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    caret: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      default: 'dropdown__body',
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      d_open: this.open,
      d_closeTimer: null,
    };
  },
  computed: {
    c_toggleClass() {
      return this.caret ? 'dropdown__toggle_caret' : null;
    },
    c_bodyStyle() {
      return {
        width: this.width,
        padding: this.padding,
        overflow: this.overflow,
      };
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled && !this.contextMenu) {
        this.toggle();
      }
    },
    handleContextMenu() {
      if (!this.disabled && this.contextMenu) {
        this.toggle();
      }
    },

    toggle(event, val) {
      this.d_open = val !== undefined ? val : !this.d_open;

      this.$emit('toggle', this.d_open, this.$el);

      if (!this.d_open) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }

      setTimeout(() => {
        if (this.d_open) {
          document.body.addEventListener('click', this.handleBodyClick);
        } else {
          document.body.removeEventListener('click', this.handleBodyClick);
        }
      }, 50);
    },
    handleBodyClick(event) {
      if (!event.composedPath().includes(this.$el)) {
        this.toggle(false);
      }
    },
    handleMouseEnter() {
      if (this.hoverOpen) {
        clearTimeout(this.d_closeTimer);
        this.toggle(null, true);
      }
    },
    handleMouseLeave() {
      if (this.hoverOpen) {
        this.d_closeTimer = setTimeout(() => {
          this.toggle(null, false);
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
}

.dropdown__toggle {
  cursor: pointer;
}

.dropdown__toggle_caret {
  &:after {
    display: inline-block;
    content: '\e901';
    font-family: icomoon;
    transition: transform 0.2s ease;
    margin-left: 5px;
    font-size: 5px;
    color: $gray;
    vertical-align: middle;
  }
}

.dropdown_open {
  .dropdown__toggle_caret:after {
    transform: rotate(180deg);
  }
}

.dropdown_disabled {
  cursor: auto;
}

.dropdown__body {
  font-size: 14px;
  //max-width: 100%;
  flex-direction: column;
  background: white;
  position: absolute;
  right: 0;
  margin-top: 16px;
  overflow: hidden;
  max-width: calc(100vw - 60px);
  border-radius: 10px;
  box-shadow: $new-shadow;
}

.dropdown__body > ul {
  padding: 0;
  margin: 0;
  list-style: none;

  [role='button'] {
    padding: 12px 15px;
    line-height: 1.2;
    display: block;
    color: $dark !important;

    &:hover {
      background: #f2f2f2;
      text-decoration: none;
    }
  }

  &.small {
    font-size: 14px;

    [role='button'] {
      padding: 6px 15px;
    }
  }
}

.dropdown__popper-body {
  z-index: 101;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition:
    opacity 0.1s,
    transform 0.1s;
  pointer-events: none;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}

.dropdown__divider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
}
</style>

export type EntityInfo = {
  name: string;
  description: string;
  title: string;
  placeholder: string;
  lastProjectsTitle: string;
  otherProjectTitle: string;
  otherProjectDescription: string;
  sameProjectTitle: string;
  pages: number;
  link: string;
  seo: {
    title: string;
    description: string;
    keywords?: string;
  };
};
export type EntityType = 'project' | 'referat' | 'course-work' | 'text' | 'report';

export const entities: Record<EntityType, EntityInfo> = {
  project: {
    name: 'Проект',
    description: '10-20 страниц',
    title: '<span style="color: $new-fr-theme">Индивидуальный проект</span> с&nbsp;помощью нейросети',
    placeholder: 'Введите тему проекта',
    lastProjectsTitle: 'Последние проекты',
    otherProjectTitle: 'Нужен другой проект?',
    otherProjectDescription: 'Создай проект на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие проекты',
    pages: 20,
    link: '/',
    seo: {
      title: 'Генератор проектов с помощью нейронной сети',
      description:
        'Генерация школьных, курсовых, дипломных работ и индивидуальных проектов с помощью нейронной сети. Цели, задачи, содержание из 10 разделов уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания проекта, нейросеть для создания проекта, нейросеть пишет проект, написать проект с помощью нейросети, нейросеть для написания проектов, нейросеть написать проект, нейросеть придумывает цели',
    },
  },
  referat: {
    name: 'Реферат',
    description: '10-15 страниц',
    title: 'Сгенерируй <span style="color: $new-fr-theme">реферат</span> с&nbsp;помощью нейросети',
    placeholder: 'Введите тему реферата',
    lastProjectsTitle: 'Последние рефераты',
    otherProjectTitle: 'Нужен другой реферат?',
    otherProjectDescription: 'Создай реферат на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие рефераты',
    pages: 10,
    link: '/referat',
    seo: {
      title: 'Нейросеть для реферата',
      description:
        'Генератор рефератов помощью нейронной сети. Цели, задачи, содержание из 10 разделов 20 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания реферата, нейросеть для создания реферата, нейросеть пишет реферат, написать реферат с помощью нейросети, нейросеть для написания рефератов, нейросеть написать реферат',
    },
  },
  'course-work': {
    name: 'Курсовая',
    description: '20-25 страниц',
    title: 'Создай <span style="color: $new-fr-theme">курсовой проект</span> с&nbsp;помощью нейросети',
    placeholder: 'Введите тему курсовой',
    lastProjectsTitle: 'Последние курсовые',
    otherProjectTitle: 'Нужна другая курсовая?',
    otherProjectDescription: 'Создай курсовую работу на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие курсовые',
    pages: 30,
    link: '/course-work',
    seo: {
      title: 'Нейросеть для курсовой работы',
      description:
        'Генератор курсовых работ помощью нейронной сети. Цели, задачи, содержание из 10 разделов уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для курсовой, нейросеть пишет курсовую, написать курсовую с помощью нейросети, нейросеть для написания курсовой, нейросеть написать курсовой, нейросеть придумывает курсовую',
    },
  },
  report: {
    name: 'Доклад',
    description: '10-15 страниц',
    title: 'Создай <span style="color: $new-fr-theme">доклад с презентацией</span> с&nbsp;помощью нейросети',
    placeholder: 'Введите тему доклада',
    lastProjectsTitle: 'Последние доклады',
    otherProjectTitle: 'Нужен другой доклад?',
    otherProjectDescription: 'Создай доклад на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие доклады',
    pages: 10,
    link: '/report',
    seo: {
      title: 'Нейросеть для доклада',
      description:
        'Генератор докладов с помощью нейронной сети. Цели, задачи, содержание из 10 разделов, 10 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания доклада, нейросеть для создания доклада, нейросеть пишет доклад, написать доклад с помощью нейросети, нейросеть для написания докладов, нейросеть написать доклад',
    },
  },
  text: {
    name: 'Текст',
    description: '15-20 страниц',
    title: 'Создай <span style="color: $new-fr-theme">уникальный текст</span> с&nbsp;помощью нейросети',
    placeholder: 'Введите тему текста',
    lastProjectsTitle: 'Последние тексты',
    otherProjectTitle: 'Нужен другой текст?',
    otherProjectDescription: 'Создай текст на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие тексты',
    pages: 30,
    link: '/text',
    seo: {
      title: 'Нейросеть для текста',
      description:
        'Текст с помощью нейронной сети. Содержание из 10 разделов 20 страниц уникального текста, список литературы, экспорт в Word.',
      keywords:
        'нейросеть для написания текста, нейросеть для создания текста, нейросеть пишет текст, написать текст с помощью нейросети, нейросеть для написания текстов, нейросеть написать текст',
    },
  },
};

<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import VAuth from '~/components/new/auth/VAuth.vue';

interface AuthModalProps {
  isRegister: boolean;
}

const props = withDefaults(defineProps<AuthModalProps>(), {
  isRegister: false,
});
const showRegisterForm = ref<boolean>(props.isRegister);
defineEmits(['closedAuthModal']);
</script>

<template>
  <v-modal
    @close="
      showRegisterForm = false;
      $emit('closedAuthModal');
    "
  >
    <v-auth
      :is-register="showRegisterForm.valueOf()"
      @close-auth="
        showRegisterForm = false;
        $emit('closedAuthModal');
      "
    />
  </v-modal>
</template>

<style lang="scss"></style>

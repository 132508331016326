export function toMoney(s: string) {
  const m = s.toString();
  return [
    ...([...m]
      .reverse()
      .join('')
      .match(/(.{1,3})/gim)
      ?.join('\u00A0') || ''),
  ]
    .reverse()
    .join(''); // '\u00A0' - неразрывный пробел &nbsp;
}

export const filePic = async (filename: string) => {
  const f = filename.split('/')[-1] || 'pdf';
  return await $fetch('/filePic/' + f);
};

export const iconColors = {
  primary: '#5047E6',
  primaryFade: '#5047E6',
  success: '#26BD6C',
  primaryLight: '#8C75FF',
  star: 'hsla(44, 100%, 50%, 1)',
  starFade: 'hsla(44, 100%, 50%, 0.4)',
};

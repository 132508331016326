import { entities } from '~/enitityHelper';
import { useAuthStore } from '~/stores/AuthStore';
import { useUserStore } from '~/stores/UsersStore';

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const authStore = useAuthStore();

  const token = to.query.token;
  if (!authStore.authToken && token?.length) {
    authStore.loginByToken(token as string);
    return navigateTo({ ...to, query: {} }, { replace: true });
  }

  if (authStore.authToken && !userStore.user) {
    await userStore.getUser();
  }
  // 'projects-id',
  if (!['topics', 'topics-slug', 'projects-id', 'index', 'yandex', 'vk', ...Object.keys(entities)].includes(<string>to.name)) {
    if (!userStore.user) {
      return navigateTo('/');
    }
  }
});

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { BriefcaseBusiness, ChevronDown, LogIn, LogOut, TextSearch, X } from 'lucide-vue-next';

import { iconColors } from 'assets/js/utils';
import { getUserProjects } from '~/api/user';
import VDropdown from '~/components/common/VDropdown.vue';
import ContextMenuCell from '~/components/new/ContextMenuCell.vue';
import VDivider from '~/components/new/VDivider.vue';
import AuthBottomSheet from '~/components/new/auth/AuthBottomSheet.vue';
import AuthModal from '~/components/new/auth/AuthModal.vue';
import { AuthStatus, useAuthStore } from '~/stores/AuthStore';
import { useUserStore } from '~/stores/UsersStore';

const userStore = useUserStore();
const authStore = useAuthStore();

authStore.$subscribe(async () => {
  if (authStore.status === AuthStatus.AUTHORIZED) {
    await userStore.getUser();
  }
});

interface HeaderProps {
  schema?: 'primary' | 'white' | 'white-line' | 'transparent';
}

const props = withDefaults(defineProps<HeaderProps>(), {
  schema: 'primary',
});
const cClass = computed(() => {
  const res = [];
  if (props.schema) {
    res.push('navbar_' + props.schema);
  }
  return res.join(' ');
});
const navSticky = ref<boolean>(false);
const showMenu = ref(false);
const ignoreElRef = ref();

const onClickOutsideHandler = [
  () => {
    showMenu.value = false;
  },
  { ignore: [ignoreElRef] },
];

onMounted(() => {
  if (window && window.ym && userStore.user) {
    window.ym(95948387, 'userParams', { id: userStore.user.id, email: userStore.user.email });
  }
  if (window) {
    onScroll();
    document.addEventListener('scroll', onScroll, { passive: true });
  }
  if (window && window.jivo_api && userStore.user) {
    setTimeout(async () => {
      window.jivo_api.setContactInfo({
        name: userStore.user.name,
        email: userStore.user.email,
        phone: userStore.user.phone,
        description: `https://begemot.ai/nova/resources/users/${userStore.user.id}`,
      });

      const projects = await getUserProjects();
      const data = projects.slice(0, 10).map((p) => ({
        key: `#${p.id}`,
        link: `https://begemot.ai/projects/${p.id}`,
        title: p.title,
        content: p.payed_status ? `Оплачен: ${(p.services || []).join(',')}` : 'Не оплачен',
      }));
      window.jivo_api.setCustomData(data);
    }, 5000);
  }
});

onBeforeUnmount(() => {
  if (window) {
    document.removeEventListener('scroll', onScroll);
  }
});

function onScroll() {
  const rect = window.scrollY;
  navSticky.value = rect > 10;
}

const route = useRoute();

function logout() {
  authStore.logout();
  if (route.name === 'profile') {
    navigateTo('/');
  } else {
    location.reload();
  }
  userStore.user = undefined;
}
</script>

<template>
  <header
    id="top"
    class="header"
    :class="{ navbar_sticky: navSticky }"
  >
    <nav
      ref="nav"
      class="navbar container"
      :class="cClass"
    >
      <div class="navbar__content">
        <div class="navbar__logo-wrap">
          <nuxt-link
            to="/"
            class="navbar__logo"
          >
            <img
              src="/images/new/logo.svg"
              alt="Бегемот"
              style="width: 24px; height: 24px"
            />
            Бегемот
          </nuxt-link>
        </div>
        <div class="navbar__content__right">
          <div class="navbar__content__right__links">
            <a href="/topics">
              <new-button
                small
                schema="transparent"
              >
                Темы проектов
              </new-button></a
            >
          </div>
          <template v-if="userStore.user">
            <new-button
              ref="ignoreElRef"
              class="navbar__menu-button"
              schema="icon"
              @click="showMenu = !showMenu"
            >
              <transition name="fade">
                <img
                  v-if="userStore?.user?.avatar && !showMenu"
                  style="right: 0"
                  class="position-absolute navbar__profile-avatar"
                  :src="userStore?.user?.avatar"
                  alt=""
                />
                <span
                  v-else-if="!showMenu"
                  style="right: 0"
                  class="position-absolute navbar__profile-btn navbar__profile-btn_mobile"
                >
                  {{ userStore.user?.name[0] || 'U' }}
                </span>
                <X
                  v-else
                  style="position: relative; right: 3px"
                  :size="24"
                  :color="iconColors.primary"
                />
              </transition>
            </new-button>
          </template>
          <new-button
            v-else
            class="navbar__login-btn"
            schema="transparent"
            @click="authStore.showAuth = true"
          >
            <LogIn
              :size="24"
              :color="iconColors.primary"
            />
          </new-button>
          <div class="navbar__group">
            <v-dropdown
              v-if="userStore.user"
              width="218px"
              padding="4px"
              class="navbar__dropdown"
            >
              <div class="navbar__profile-btn-wrap">
                <img
                  v-if="userStore?.user?.avatar"
                  class="navbar__profile-avatar"
                  :src="userStore?.user?.avatar"
                  alt=""
                />
                <new-button
                  v-else
                  class="navbar__profile-btn"
                  outline
                >
                  {{ userStore.user.name[0] || 'U' }}
                </new-button>
                <ChevronDown :size="20" />
              </div>
              <template #body>
                <ul>
                  <li>
                    <context-menu-cell
                      small
                      @click="navigateTo('/profile')"
                    >
                      Мои заказы
                    </context-menu-cell>
                  </li>
                  <li class="dropdown__divider">
                    <v-divider
                      is-gray
                      style="background: rgba(#999, 0.05)"
                    />
                  </li>
                  <li>
                    <context-menu-cell
                      small
                      @click="logout"
                    >
                      Выйти
                    </context-menu-cell>
                  </li>
                </ul>
              </template>
            </v-dropdown>
            <div
              v-else
              class="navbar__buttons"
            >
              <new-button
                small
                schema="outline"
                @click="
                  authStore.showAuth = true;
                  authStore.showRegister = false;
                "
              >
                Войти
              </new-button>
              <new-button
                schema="outline"
                small
                @click="
                  authStore.showAuth = true;
                  authStore.showRegister = true;
                "
              >
                Зарегистрироваться
              </new-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-on-click-outside="onClickOutsideHandler"
        class="navbar__links"
        :class="{ 'navbar__links-open': showMenu }"
      >
        <ul v-if="userStore.user">
          <li>
            <nuxt-link
              to="/profile"
              class="navbar__link"
              @click="showMenu = false"
            >
              <BriefcaseBusiness
                :color="iconColors.primary"
                :size="20"
              />
              Мои заказы
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              to="/topics"
              class="navbar__link"
              @click="showMenu = false"
            >
              <TextSearch
                :color="iconColors.primary"
                :size="20"
              />
              Темы проектов
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              class="navbar__link"
              @click.prevent="
                showMenu = false;
                logout();
              "
            >
              <LogOut
                :color="iconColors.primary"
                :size="20"
              />
              Выйти
            </nuxt-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <AuthModal
    v-if="authStore.showAuth && !$device.isMobile"
    :is-register="authStore.showRegister"
    @closed-auth-modal="
      authStore.showAuth = false;
      authStore.showRegister = false;
    "
  />
  <AuthBottomSheet
    :is-open="authStore.showAuth && $device.isMobile"
    @closed-auth-bottom-sheet="
      authStore.showAuth = false;
      authStore.showRegister = false;
    "
  />
</template>

<style lang="scss">
.header {
  &.navbar_sticky > .navbar > .navbar__content {
    box-shadow: 0 4px 40px #00000017;
  }

  &.navbar_sticky > .navbar:has(.navbar__links-open) > .navbar__content {
    box-shadow: 0px 0px 0px #00000017;
  }

  .navbar {
    z-index: 1000;

    position: fixed;
    left: calc((100vw - 100%) / 2 + 50%);
    translate: -50%;
    top: 16px;

    @include media-breakpoint-down(sm) {
      padding-left: 4px;
      padding-right: 4px;
      top: 4px;
      // margin: auto 4px;
    }

    .navbar__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;

      border-radius: 16px;
      width: 100%;
      background: white;
      box-shadow: 0px 0px 0px #00000017;
      transition: box-shadow 0.2s ease-in-out;

      @include media-breakpoint-down(md) {
        padding: 12px 16px;
        top: 4px;
        width: 100%;
      }
    }

    .navbar__profile-btn {
      background: $new-bg-theme-fade;
    }

    .navbar__logo {
      display: flex;
      align-items: center;
      color: $new-fr-contrast;
      gap: 8px;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      @include media-breakpoint-down(md) {
        font-size: 18px;
        img {
          width: 32px;
        }
      }
    }

    .navbar__content__right {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      justify-content: end;

      .navbar__content__right__links {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: end;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .navbar__group {
      display: flex;
      gap: 30px;
      @include media-breakpoint-down(lg) {
        gap: 24px;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .navbar__dropdown {
      .dropdown__body {
        .divider {
          background: rgba($new-fr-contrast, 0.1);
        }
      }

      .navbar__profile-btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        border-radius: 12px;
        transition: 0.3s;
        padding: 4px;

        &:hover {
          background: $new-hover-gray;
        }
      }
    }

    .navbar__profile-btn {
      padding: 0;
      width: 32px;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 100px;
      border: 1px solid $new-hover-gray;
      background: $new-bg-theme-fade;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $new-fr-theme;
      background: white;

      &.navbar__profile-btn_mobile {
        display: none;
        @include media-breakpoint-down(md) {
          display: flex;
        }
      }
    }

    .navbar__profile-avatar {
      object-fit: cover;
      max-width: 32px;
      width: 32px;
      max-height: 32px;
      height: 32px;
      border-radius: 100px;
    }

    .navbar__login-btn {
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .navbar__buttons {
      display: flex;
      gap: 12px;
    }

    .navbar__menu-button {
      position: relative;
      display: none;
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        color: $new-fr-contrast;
      }
    }

    .navbar__links {
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
        list-style: none;
        margin: 0;
        overflow: hidden;
        width: 100%;
        border-radius: 16px;
        margin: 0 4px;
        max-height: 0;
        transition: all 0.25s ease-in-out;
        box-shadow: 0 0 0 #00000017;
        background: white;
        position: absolute;
        top: 30px;
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
        z-index: -1;

        li {
          display: block;
          padding: 0;
          transition: all 0.15s ease-in-out;
          opacity: 0;

          .navbar__link {
            padding: 12px 0;
            display: flex;
            align-items: center;
            color: $new-fr-contrast;
            gap: 12px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      &.navbar__links-open {
        top: 30px;
        max-height: 100dvh;
        box-shadow: 0 4px 40px #00000017;
        padding-top: 30px;

        li {
          opacity: 1;
        }
      }
    }
  }
}
</style>

<script setup lang="ts">
interface ButtonProps {
  schema?: 'primary' | 'outline' | 'disabled' | 'secondary' | 'right' | 'transparent' | 'icon' | 'text';
  outline?: boolean;
  background?: string;
  secondary?: boolean;
  disabled?: boolean;
  right?: boolean;
  type?: 'button' | 'submit' | 'reset';
  iconClassLeft?: string;
  iconClassRight?: string;
  small?: boolean;
  medium?: boolean;
  loading?: boolean;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  schema: 'primary',
  background: '',
  type: 'button',
  iconClassLeft: undefined,
  iconClassRight: undefined,
  small: false,
  medium: false,
});

const cClass = computed(() => {
  const res = ['new-button_' + props.schema];
  if (props.outline) {
    res.push('new-button_outline');
  }
  if (props.disabled) {
    res.push('new-button_disabled');
  }
  if (props.secondary) {
    res.push('new-button_secondary');
  }
  if (props.right) {
    res.push('new-button_right');
  }
  if (props.small) {
    res.push('new-button_sm');
  }
  if (props.medium) {
    res.push('new-button_md');
  }
  return res.join(' ');
});
</script>

<template>
  <button
    class="new-button"
    :class="cClass"
    :type="type"
    :disabled="disabled"
  >
    <span
      v-if="loading"
      class="new-loader"
    />
    <span
      v-if="iconClassLeft"
      class="new-button__icon-left"
    >
      <i
        class="icon"
        :class="iconClassLeft"
      />
    </span>
    <slot name="iconLeft" />
    <slot />
    <slot name="iconRight" />
    <span
      v-if="iconClassRight"
      class="new-button__icon-right"
    >
      <i
        class="icon"
        :class="iconClassRight"
      />
    </span>
  </button>
</template>

<style lang="scss">
.new-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 24px;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  border: none;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    text-decoration: none;
  }

  &.new-button_sm {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 16px;
  }

  &.new-button_default {
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &.new-button_md {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    height: 56px;
  }

  &.new-button_right {
    border-radius: 0 12px 12px 0;
  }

  &.new-button_disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.new-button_text {
    padding: 0;
    color: $new-fr-theme;
    font-weight: 500;
    display: inline;
    font-size: 14px;
    line-height: 20px;
    background: transparent;
  }

  &:focus {
    outline: none;
  }
}

.new-loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: relative;

  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.new-button__icon-left,
.new-button__icon-right {
  //margin-right: 8px;
  display: flex;
  align-items: center;
}

.new-button_primary {
  color: #fff;
  background: $new-fr-theme;

  &:hover {
    color: #fff;
    background: #6059e9;
  }

  .new-loader {
    background: linear-gradient(0deg, rgb(255 255 255 / 20%) 33%, #ffffff 100%);

    &::after {
      background: $new-fr-theme;
    }
  }
}

.new-button_secondary {
  color: $new-fr-theme;
  background: $new-bg-theme-fade;

  &:hover {
    background: rgba($new-fr-theme, 0.1);
  }
  .new-loader {
    background: linear-gradient(0deg, rgba($new-fr-theme, 0) 33%, $new-fr-theme 100%);

    &::after {
      background: $new-bg-theme-fade;
    }
  }
}

.new-button_outline {
  color: $new-fr-contrast;
  background: $new-bg-theme;

  &:hover {
    color: $new-fr-contrast;
    background: $new-hover-gray;
  }
}

.new-button_transparent {
  color: $new-fr-contrast !important;
  border: none;
  background: transparent;

  &:hover {
    background: $new-hover-gray;
  }
}

.new-button_icon {
  padding: 0;
  border: none;
  background: transparent;
}

.new-button_transparent {
  color: $new-fr-theme;
  border: none;
  background: transparent;
}

.new-button_icon {
  padding: 0;
  border: none;
  background: transparent;
}
</style>
